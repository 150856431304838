exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apps-appstore-index-js": () => import("./../../../src/pages/apps/appstore/index.js" /* webpackChunkName: "component---src-pages-apps-appstore-index-js" */),
  "component---src-pages-apps-googleplay-index-js": () => import("./../../../src/pages/apps/googleplay/index.js" /* webpackChunkName: "component---src-pages-apps-googleplay-index-js" */),
  "component---src-pages-apps-index-js": () => import("./../../../src/pages/apps/index.js" /* webpackChunkName: "component---src-pages-apps-index-js" */),
  "component---src-pages-help-braintraining-datasafety-index-js": () => import("./../../../src/pages/help/braintraining/datasafety/index.js" /* webpackChunkName: "component---src-pages-help-braintraining-datasafety-index-js" */),
  "component---src-pages-help-braintraining-transfer-import-android-index-js": () => import("./../../../src/pages/help/braintraining/transfer/import/android/index.js" /* webpackChunkName: "component---src-pages-help-braintraining-transfer-import-android-index-js" */),
  "component---src-pages-help-braintraining-transfer-import-ios-index-js": () => import("./../../../src/pages/help/braintraining/transfer/import/ios/index.js" /* webpackChunkName: "component---src-pages-help-braintraining-transfer-import-ios-index-js" */),
  "component---src-pages-help-shopping-datasafety-index-js": () => import("./../../../src/pages/help/shopping/datasafety/index.js" /* webpackChunkName: "component---src-pages-help-shopping-datasafety-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-notification-kanjinumbercrossword-android-20230104-index-js": () => import("./../../../src/pages/notification/kanjinumbercrossword/android/20230104/index.js" /* webpackChunkName: "component---src-pages-notification-kanjinumbercrossword-android-20230104-index-js" */),
  "component---src-pages-notification-kanjinumbercrossword-ios-20230104-index-js": () => import("./../../../src/pages/notification/kanjinumbercrossword/ios/20230104/index.js" /* webpackChunkName: "component---src-pages-notification-kanjinumbercrossword-ios-20230104-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

